.content-text {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
    margin: 15px 0;
  }

  p {
    margin-bottom: 20px;
    line-height: 35px;
    color: rgb(113, 133, 133);
    font-size: .875rem;
  }
}