.resume-content {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    line-height: 30px;
    font-weight: 800;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #555555;
    margin-bottom: 40px;
  }

  ul {
    padding-left: 20px;
    line-height: 30px;
    color: #555555;

    li {
    list-style: disc;
      text-indent: 10px;
    }
  }
}

.send-resume-form {
  .select-file {
    label {
      background: #F3F3F3;
      border: 1px dashed #1B262C;
      border-radius: 10px;
      width: 100%;
      height: 63px;
      display: inline-block;
      text-align: center;
      line-height: 63px;
      cursor: pointer;
      color: #1B262C;
      font-size: 13px;
    }

    input {
      display: none;
    }
  }
}