@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: IRANYekan;
    font-weight: 300;
    src: url("./assets/font/IRANYekanWebThin.woff") format("woff");
  }
  @font-face {
    font-family: IRANYekan;
    font-weight: 400;
    src: url("./assets/font/IRANYekanWebRegular.woff") format("woff");
  }
  @font-face {
    font-family: IRANYekan;
    font-weight: 500;
    src: url("./assets/font/IRANYekanWebRegular.woff") format("woff");
  }
  @font-face {
    font-family: IRANYekan;
    font-weight: 600;
    src: url("./assets/font/IRANYekanWebMedium.woff") format("woff");
  }
  @font-face {
    font-family: IRANYekan;
    font-weight: 800;
    src: url("./assets/font/IRANYekanWebExtraBold.woff") format("woff");
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


*,
body {
  font-family: IRANYekan;
  list-style: none;
}

.num-fa {
  font-family: IRANYekan;
}

:root {
  --primary-color: #0098ff;
  --secondary-color: #0063ff;
  --thirdly-color: #fdfdfd;
  --fourthly-color: #1b262c;
}

.linkContainer:hover .icons {
  padding: 12px 0;
  border-bottom: 2px solid #ffff;
  border-radius: 1px;
}

.link-under-line {
  padding: 12px 0;
  border-bottom: 2px solid #ffff;
  border-radius: 1px;
}

.custom-gradient {
  background: linear-gradient(
                  265.63deg,
                  rgba(255, 255, 255, 0.1) -12.06%,
                  rgba(255, 255, 255, 0) 111.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.swiper-button-next {
  background-image: url(./assets/svg/LeftSliderArrow.svg);
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(./assets/svg/RightSliderArrow.svg);
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.leaflet-container {
  width: 526px;
  height: 324px;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.05);
}

//table tr:last-child td:first-child {
//  border-bottom-right-radius: 20px;
//}
//
//table tr:last-child td:last-child {
//  border-bottom-left-radius: 20px;
//}
//
//table tr:first-child th:last-child {
//  border-top-left-radius: 20px;
//}

@keyframes bounceone {
  0%, 100% {
    transform: translateY(-25%);
  }
  50% {
    transform: none;
  }
}

.my-animate-one {
  animation: bounceone 3s linear infinite;
}

@keyframes bounceTwo {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: none;
  }
}

.my-animate-two {
  animation: bounceTwo 3s linear infinite;
}

@keyframes bounceThree {
  0%, 100% {
    transform: translateY(-5%);
  }
  50% {
    transform: none;
  }
}

.my-animate-three {
  animation: bounceThree 3s linear infinite;
}

.card-anime:hover {
  animation: myCardAnime 0.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: alternate;
}

.card-scale-anime {
  transition: .3s;
  transform: scale(1);
}

.card-scale-anime:hover {
  transform: scale(1.02);
}

@keyframes myCardAnime {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 20px;
  }
}

//.table-bg {
//  background-image: url(./assets/svg/TableBg.svg);
//  background-size: cover;
//  left: -0.5px;
//}
//
//.table-bg2 {
//  background-image: url(./assets/svg/TableBg2.svg);
//  background-size: cover;
//}

.plan-detail-anime {
  position: relative;
  animation: planAnime 0.3s linear;
}

@keyframes planAnime {
  0% {
    opacity: 0.9;
    bottom: 10px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

.hover-icon:hover .icon-hidden {
  display: none;
}

.hover-icon:hover .icon-show {
  display: block;
}

.paginate {
  .disabled {
    opacity: .5;
  }
}

.plan-table-res {
  .swiper-button-next,
  .swiper-button-prev {
    box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 34px;
    height: 34px;
    top: 135px;
  }

  .swiper-button-prev {
    background: #FFFFFF url("assets/svg/arrow-right.svg") no-repeat center;
  }

  .swiper-button-next {
    background: #FFFFFF url("assets/svg/arrow-left.svg") no-repeat center;
  }
}

/* Section Heading */
.section-heading {
  strong {
    font-weight: 700;
  }
}